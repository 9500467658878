import React, { useState, useEffect } from 'react';
import LeadForm from './components/form';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Divider, Segment, Button, Icon, Form, Checkbox, Label } from 'semantic-ui-react';
import * as qs from 'query-string';
import { useCookies } from 'react-cookie';
import { isEmpty, find } from 'lodash';
import Api from './lib/api';
import 'semantic-ui-css/semantic.min.css';
import './App.css';

function App() {
	const [ catalog, setCatalog ] = useState(null);
	const [ params, setParams ] = useState(null);
	const [ logo, setLogo ] = useState(null);
	const [ cover, setCover ] = useState(null);

	const [ confirm, setConfirm ] = useState(false);
	const [ user, setUser ] = useState({});
	const [ agent, setAgent ] = useState({});

	const [ cookies, setCookie ] = useCookies([ 'urls' ]);
	const [ vistUrls, setVistUrls ] = useState([]);

	const [ loading, setLoading ] = useState(true);

	const urlParameters = qs.parse(window.location.search, { ignoreQueryPrefix: true });
	const continueUrl = urlParameters.continue;

	const resourceUrl = urlParameters.resource;

	const responseGoogle = async (response) => {
		console.log('responseGoogle ', response);
		const profile = response.profileObj;
		const payload = {
			fname: profile.givenName,
			lname: profile.familyName,
			email: profile.email
		};
		setUser(payload);
		setConfirm(true);
	};
	const responseFacebook = (response) => {
		console.log('responseFacebook ', response);
		const profile = response;
		const fullname = profile.name.split(' ');
		const payload = {
			fname: fullname[0] || '',
			lname: fullname[1] || '',
			email: profile.email
		};
		setUser(payload);
		setConfirm(true);
	};

	useEffect(() => {
		const nextUrl = continueUrl || resourceUrl;

		try {
			const urls = !isEmpty(cookies.urls) ? cookies.urls : [];
			// if current url exist in array skip form and redirect
			const match = find(urls, function(u) {
				return u.url == nextUrl;
			});
			console.log('match ', match, urls, nextUrl);
			if (!isEmpty(match)) {
				doRedirect(match.leadid);
			}
			console.log('urls loaded: ', match, JSON.stringify(urls));
			setVistUrls(urls);
		} catch (e) {
			console.log('detect visit and redirect error! ', e);
		}

		if (nextUrl) {
			let params = !isEmpty(continueUrl)
				? getUrlParameters(nextUrl)
				: {
						padUID: urlParameters.padUID,
						padOID: urlParameters.padOID
					};
			setParams(params);
			console.log('PARAMS ', params);

			const fetchData = async () => {
				const { padUID, padOID } = params;
				const groupId = isEmpty(params.groupId)?urlParameters.groupID:params.groupId;
				
				console.log("groupId ", groupId)

				if (!isEmpty(groupId)) {
					const { data } = await Api.getActiveGroup(groupId);
					console.log('group ', data);
					const { catalog_id, cover } = data;

					let result = await Api.getDynalog(catalog_id, padUID);
					console.log('Dynalog ', result);
					if (!isEmpty(result.data)) {
						const catalog = result.data;
						console.log('CATALOG ', catalog);
						setCatalog(catalog);
						let coverUrl = getUrlCover(cover.src);
						console.log('coverUrl ', coverUrl);
						setCover(coverUrl);
					}
				}

				if (!isEmpty(padUID)) {
					let padUser = await Api.getUser(padUID);

					let padOrg = await Api.getOrg(padOID);

					if (!isEmpty(padUser.data)) {
						const agent = padUser.data;
						const org = padOrg.data;
						console.log('agent ', agent);
						setAgent(agent);
						const logo = `https://padapi.catalogs.com/public/upload/${org.logo}`;
						setLogo(logo);
					}
				}
			};

			fetchData();
		}
		setLoading(false);
	}, []);

	const getUrlCover = (url) => {
		try {
			new URL(url);
			return url;
		} catch (_) {
			return `//cdn.catalogs.com${url}`;
		}
	};

	const onSubmit = async (lead) => {
		let leadId = null;
		const payload = {
			fname: lead.firstName,
			lname: lead.lastName,
			email: lead.email,
			phone: lead.phoneNumber,
			catalog: catalog || '{}',
			resources: resourceUrl || '',
			user: agent,
			orgId: !isEmpty(urlParameters.padOID) ? urlParameters.padOID : !isEmpty(agent) ? agent.org.id : 0
		};
		if (confirm) {
			try {
				const { data } = await saveLead(payload);
				leadId = data.leadid;
			} catch (e) {
				console.log('error creating lead ', e);
			}

			//save url to cookie array to skip lead form next time
			const nextUrl = continueUrl || resourceUrl;
			let urlCookies = [].concat(vistUrls);
			urlCookies.push({
				url: nextUrl,
				leadid: leadId
			});
			console.log('save cookie ', urlCookies);
			setCookie('urls', JSON.stringify(urlCookies));

			doRedirect(leadId);
		} else {
			setUser(payload);
			setConfirm(true);
		}
	};

	const saveLead = async (payload) => {
		return await Api.saveLead(payload);
	};

	const doRedirect = (leadId = null) => {
		if (!continueUrl.includes('catalogs.com')) {
			window.location = catalog.url;
			return;
		}

		if (continueUrl) {
			let continueParams = getUrlParameters(continueUrl);
			const newContinue = new URL(continueUrl);
			let newContinueUrl = `${newContinue.origin}${newContinue.pathname}?${qs.stringify(continueParams)}`;
			if (leadId) {
				newContinueUrl += `&padLID=${leadId}`;
			}

			console.log('redirect to dynalog!!! ', newContinueUrl);
			window.location = newContinueUrl;
		} else if (resourceUrl) {
			let resourcesParams = getUrlParameters(resourceUrl);
			const newResources = new URL(resourceUrl);
			let newResourcesUrl = `${newResources.origin}${newResources.pathname}`;
			if (leadId) {
				const payload = {
					campaign: '',
					tracking_source: 'U',
					catID: '0',
					catName: '0',
					catGroup: '0',
					action: 'Open',
					pageType: 'Resource',
					site: 'DynalogApp',
					identity: leadId,
					referalURL: newResourcesUrl,
					utmcsr: 'undefined',
					utmccn: 'undefined',
					utmcmd: 'undefined',
					utmctr: 'undefined'
				};
				// track resource open using leadid
				try {
					Api.trackResource(payload);
				} catch (_) {}
			}
			console.log('redirect to resource!!! ', newResourcesUrl);
			//redirect
			window.location = newResourcesUrl;
		} else {
			window.location = 'https://dynalog.catalogs.com';
		}
	};

	const getUrlParameters = (url) => {
		const urlObject = new URL(url);
		console.log('getUrlParameters for ', url, qs.parse(urlObject.search, { ignoreQueryPrefix: true }));
		const urlParts = urlObject.pathname;
		const groupId = urlParts.split('/')[1].substr(1);
		let continueParams = qs.parse(urlObject.search, { ignoreQueryPrefix: true });
		continueParams.groupId = groupId;
		delete continueParams.optin;

		return continueParams;
	};

	return loading ? (
		<div>loading...</div>
	) : (
		<Segment
			basic
			className="App"
			style={{
				backgroundImage: `url(${cover})`
			}}
		>
			{logo && (
				<div className="logo">
					<img alt="Logo" src={logo} />
				</div>
			)}
			<div className="content">
				{confirm && user ? (
					<div>
						<h4>Confirm information:</h4>
						<LeadForm onSubmit={onSubmit} confirm={true} user={user} />
					</div>
				) : (
					<div>
						<LeadForm onSubmit={onSubmit} />
						<Divider horizontal>Or</Divider>
						<div className="socialButtons">
							<GoogleLogin
								clientId="878596251576-ure3bi54f2ob48tljdeooifef03oi3rm.apps.googleusercontent.com"
								buttonText="Continue with Google"
								onSuccess={responseGoogle}
								onFailure={(err) => console.log('fail', err)}
								cookiePolicy={'single_host_origin'}
								render={(renderProps) => (
									<Button onClick={renderProps.onClick} color="google plus">
										<Icon name="google" /> Google
									</Button>
								)}
							/>
							{/*
							<FacebookLogin
								appId="2495338540710200"
								autoLoad={false}
								fields="name,email,picture"
								callback={responseFacebook}
								render={(renderProps) => (
									<Button onClick={renderProps.onClick} color="facebook">
										<Icon name="facebook" /> Facebook
									</Button>
								)}
							/>
							*/}
						</div>
					</div>
				)}
			</div>
		</Segment>
	);
}

export default App;
