import axios from 'axios';
var instance = axios.create();
delete instance.defaults.headers.common['X-CSRF-TOKEN'];
delete instance.defaults.headers.common['X-Requested-With'];

const dynalogEndpoint = process.env.REACT_APP_DYNALOG_API || 'https://api-qa.catalogs.com';
const leadxEndpoint = process.env.REACT_APP_LEADX_API || 'https://leadxapi.dev.catalogshub.com';

class Api {
	static getDynalog(groupId, padId) {
		return instance.get(`${dynalogEndpoint}/v1/catalogs/${groupId}?pad_user_id=${padId}`);
    }
    static getActiveGroup(groupId){
        return instance.get(`${dynalogEndpoint}/v1/groups/${groupId}/active`);
    }
    static getUser(userId){
        return instance.get(`${leadxEndpoint}/users/${userId}`);
    }
    static getOrg(orgId){
        return instance.get(`${leadxEndpoint}/orgs/${orgId}`);
    }
    static getUserOrg(userId){
        return instance.get(`${leadxEndpoint}/users/${userId}`);
    }
    static saveLead(payload){
        //return axios.post('http://vagrant.local:8080/leads2', payload)
        return axios.post(`${leadxEndpoint}/leads2`, payload)
    }
    static trackResource(payload){
        const encodedJson = encodeURIComponent(JSON.stringify(payload));
        const url = `http://track.catalogs.com/track/?JSON=`+encodedJson;
        return instance.get(url);
    }
}

export default Api;