import React, { useState } from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react';
import useForm from 'react-hook-form';

export default function LeadForm(props) {
	const [ agree, setAgree ] = useState(false);
	const { register, errors, handleSubmit } = useForm();
	const onSubmit = (data) => {
		props.onSubmit(data);
	};
	const toggleAgree = () => {
		setAgree(!agree);
	};
	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<Form.Field>
				<label>First Name</label>
				<input
					placeholder="First Name"
					name="firstName"
					ref={register({ required: true, maxlength: 80 })}
					className={[ errors.firstName ? 'inputError' : '' ].join(' ')}
					defaultValue={props.user ? props.user.fname : ''}
				/>
				{errors.firstName && <label className="errorLabel">Your input is required</label>}
			</Form.Field>
			<Form.Field>
				<label>Last Name</label>
				<input
					placeholder="Last Name"
					name="lastName"
					ref={register({ required: true, maxlength: 100 })}
					className={[ errors.lastName ? 'inputError' : '' ].join(' ')}
					defaultValue={props.user ? props.user.lname : ''}
				/>
				{errors.lastName && <label className="errorLabel">Your input is required</label>}
			</Form.Field>
			<Form.Field>
				<label>Email</label>
				<input
					placeholder="Email"
					name="email"
					ref={register({
						required: true,
						pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					})}
					className={[ errors.email ? 'inputError' : '' ].join(' ')}
					defaultValue={props.user ? props.user.email : ''}
				/>
				{errors.email && <label className="errorLabel">Your input is required</label>}
			</Form.Field>
			<Form.Field>
				<label>Phone Number</label>
				<input
					placeholder="Phone"
					name="phoneNumber"
					ref={register({ required: true, maxLength: 11, minLength: 8 })}
					className={[ errors.phoneNumber ? 'inputError' : '' ].join(' ')}
					defaultValue={props.user ? props.user.phone : ''}
				/>
				{errors.phoneNumber && <label className="errorLabel">Your input is required</label>}
			</Form.Field>

			{props.confirm && (
				<Form.Field>
					<Checkbox
						label="by continuing you agree to share your info with client"
						onChange={toggleAgree}
						checked={agree}
					/>
				</Form.Field>
			)}
			<Button primary fluid type="submit" disabled={props.confirm && !agree}>
				{props.confirm ? 'Continue' : 'Continue'}
			</Button>
		</Form>
	);
}
